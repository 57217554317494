import {
    getDateFormat,
    OrganizationProject,
    TimeFrames,
} from '@lightdash/common';
import {
    Avatar,
    Box,
    Button,
    LoadingOverlay,
    Stack,
    Text,
} from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconChevronLeft, IconClock, IconCopy } from '@tabler/icons-react';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import useToaster from '../../../hooks/toaster/useToaster';
import { useCreateAccessToken } from '../../../hooks/useAccessToken';
import { useProjects } from '../../../hooks/useProjects';
import { useTracking } from '../../../providers/TrackingProvider';
import { EventName } from '../../../types/Events';
import MantineIcon from '../../common/MantineIcon';
import { ProjectCreationCard } from '../../common/Settings/SettingsCard';
import CodeBlock from './common/CodeBlock';
import { OnboardingTitle } from './common/OnboardingTitle';
import OnboardingWrapper from './common/OnboardingWrapper';

interface ConnectUsingCliProps {
    siteUrl: string;
    version: string;
    onBack: () => void;
}

const codeBlock = ({
    siteUrl,
    loginToken,
    version,
}: {
    siteUrl: string;
    loginToken?: string;
    version: string;
}) =>
    String.raw`
#1 install lightdash CLI
npm install -g @lightdash/cli@${version}

#2 login to lightdash
lightdash login ${siteUrl} --token ${loginToken}

#3 create project
lightdash deploy --create
`.trim();

const ConnectUsingCLI: FC<ConnectUsingCliProps> = ({
    siteUrl,
    version,
    onBack,
}) => {
    const history = useHistory();
    const initialProjectFetch = useRef(false);
    const existingProjects = useRef<OrganizationProject[]>();
    const { showToastSuccess } = useToaster();
    const queryClient = useQueryClient();
    const { track } = useTracking();
    const clipboard = useClipboard({ timeout: 200 });

    useProjects({
        refetchInterval: 3000,
        refetchIntervalInBackground: true,
        onSuccess: async (newProjects) => {
            if (!initialProjectFetch.current) {
                existingProjects.current = newProjects;
                initialProjectFetch.current = true;
            }

            if (
                existingProjects.current &&
                existingProjects.current.length < newProjects.length
            ) {
                const uuids = newProjects.map((p) => p.projectUuid);
                const existingUuids = existingProjects.current.map(
                    (p) => p.projectUuid,
                );

                const newProjectUuid = uuids.find(
                    (uuid) => !existingUuids.includes(uuid),
                );

                await queryClient.invalidateQueries('organization');

                history.replace(
                    `/createProject/cli?projectUuid=${newProjectUuid}`,
                );
            }
        },
    });

    const {
        mutate: mutateAccessToken,
        data: tokenData,
        isLoading: isTokenCreating,
        isSuccess: isTokenCreated,
    } = useCreateAccessToken();

    useEffect(() => {
        if (isTokenCreated) return;

        const expiresAt = moment().add(30, 'days').toDate();
        const generatedAtString = moment().format(
            getDateFormat(TimeFrames.SECOND),
        );

        mutateAccessToken({
            expiresAt,
            description: `Generated by the Lightdash UI for CLI at ${generatedAtString}`,
            autoGenerated: true,
        });
    }, [mutateAccessToken, isTokenCreated]);

    const codeBlockText = useMemo(() => {
        return codeBlock({
            siteUrl,
            version,
            loginToken: tokenData?.token,
        });
    }, [siteUrl, version, tokenData?.token]);

    const handleCopy = useCallback(() => {
        clipboard.copy(codeBlockText);

        showToastSuccess({ title: 'Commands copied to clipboard!' });
        track({ name: EventName.COPY_CREATE_PROJECT_CODE_BUTTON_CLICKED });
    }, [showToastSuccess, track, clipboard, codeBlockText]);

    return (
        <OnboardingWrapper>
            <Button
                pos="absolute"
                variant="subtle"
                size="sm"
                top={-50}
                leftIcon={<MantineIcon icon={IconChevronLeft} />}
                onClick={onBack}
            >
                Back
            </Button>

            <ProjectCreationCard>
                <LoadingOverlay
                    visible={!isTokenCreated || isTokenCreating}
                    overlayBlur={2}
                />

                <Stack>
                    <Stack align="center" spacing="sm">
                        <Avatar size="lg" radius="xl">
                            <MantineIcon
                                icon={IconClock}
                                size="xxl"
                                strokeWidth={1.5}
                                color="black"
                            />
                        </Avatar>

                        <Stack spacing="xxs">
                            <OnboardingTitle>Waiting for data</OnboardingTitle>

                            <Text color="dimmed">
                                Inside your dbt project, run:
                            </Text>
                        </Stack>
                    </Stack>

                    <Box pos="relative">
                        <CodeBlock>{codeBlockText}</CodeBlock>

                        <Button
                            pos="absolute"
                            right={12}
                            bottom={12}
                            variant="outline"
                            size="xs"
                            leftIcon={<MantineIcon icon={IconCopy} />}
                            onClick={handleCopy}
                        >
                            Copy
                        </Button>
                    </Box>
                </Stack>
            </ProjectCreationCard>

            <Button
                component="a"
                variant="subtle"
                mx="auto"
                w="fit-content"
                target="_blank"
                rel="noreferrer noopener"
                href="https://docs.lightdash.com/get-started/setup-lightdash/get-project-lightdash-ready"
                onClick={() => {
                    track({
                        name: EventName.DOCUMENTATION_BUTTON_CLICKED,
                        properties: {
                            action: 'getting_started',
                        },
                    });
                }}
            >
                View docs
            </Button>
        </OnboardingWrapper>
    );
};

export default ConnectUsingCLI;
