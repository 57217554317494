import {
    CompiledDimension,
    CompiledMetric,
    DimensionType,
    Explore,
    fieldId,
    FieldType,
    friendlyName,
    MetricType,
    SupportedDbtAdapter,
} from '@lightdash/common';
import {
    GetMetricFlowFieldsResponse,
    MetricFlowDimensionType,
} from '../../../api/MetricFlowAPI';

export default function convertMetricFlowFieldsToExplore(
    tableName: string,
    metricFlowFields: GetMetricFlowFieldsResponse,
) {
    const dimensionsMap = metricFlowFields.dimensions.reduce(
        (acc, { name, description, type }) => {
            const isTimeDimension = type === MetricFlowDimensionType.TIME;
            const dimension: CompiledDimension = {
                fieldType: FieldType.DIMENSION,
                type: isTimeDimension
                    ? DimensionType.TIMESTAMP
                    : DimensionType.STRING,
                // Note: time columns in results are suffixed with '__day' by default
                name: isTimeDimension ? `${name}__day` : name,
                description,
                label: friendlyName(name),
                table: tableName,
                tableLabel: '',
                sql: '',
                compiledSql: '',
                tablesReferences: [tableName],
                hidden: false,
            };

            return { ...acc, [fieldId(dimension)]: dimension };
        },
        {},
    );
    const metricsMap = metricFlowFields.metricsForDimensions.reduce(
        (acc, { name, description }) => {
            const metric: CompiledMetric = {
                isAutoGenerated: false,
                fieldType: FieldType.METRIC,
                type: MetricType.NUMBER,
                name,
                description,
                label: friendlyName(name),
                table: tableName,
                tableLabel: '',
                sql: '',
                compiledSql: '',
                tablesReferences: [tableName],
                hidden: false,
            };

            return { ...acc, [fieldId(metric)]: metric };
        },
        {},
    );

    const explore: Explore = {
        name: tableName,
        label: '',
        tags: [],
        baseTable: tableName,
        joinedTables: [],
        tables: {
            [tableName]: {
                name: tableName,
                label: '',
                database: '',
                schema: '',
                sqlTable: '',
                dimensions: dimensionsMap,
                metrics: metricsMap,
                lineageGraph: {},
            },
        },
        targetDatabase: SupportedDbtAdapter.POSTGRES,
    };
    return explore;
}
